//Global Style
  $brand-inverse : #efeeee;
  $brand-primary: #63666a;
  $brand-success: #006255;
  $brand-info: #696a6c;
  $brand-warning: #0089cf;
  $brand-danger: #7f1517;
  $brand-white: #ffffff;
  $gray-light: #18191c;
  $font-white: #ffffff;
  $font-family-base :  'Noto Sans TC', 'Open Sans',sans-serif;
  $text-muted : $gray-light;
  $navbar-light-color:#333333;
  $navbar-light-hover-color:#000000;
  $container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1200px
  );

  .AllPage-BackGroundColor {
    background-color: white;
    padding: 0 !important;
  }

  .text-inverse {
  color: $brand-inverse !important;
  }

  .navigation-black a{
  color: $gray-light
  }
  button:disabled {
  opacity: 0.5;
  }

  .text-black{
    color: black !important;
  }

  .hidden {
    display: none;
  }

//Nav Style
  .nav-contact-margin{
    padding: 0 !important;
    vertical-align:bottom;
  }

  .navbar-background,.footer-background{
    background-color: #1f3c7d ;
  }

//HomePage Carousel Styel
  .Home-Carousel-size {
    width: 100% !important;
    //height: 350px !important;
  }

  .Home-Carousel-IMG-size{
    height: 100%;
    width:100%;
    max-height: 800px !important;
  }

  .Home-Carousel-Text-BackGroundColor {
    background-color: #e6e6e6db;
    border-radius:10px;
  }

//HomePage Info Styel
  .Home-info-Margin{
    margin-bottom: 0px !important;
  }

//HomePage Container style
  .Home-Container-Row-Margin {
    margin: 10px 0px !important;
    padding: 0px !important;
  }

  .Home-Container-LeftImg-Margin {
    padding: 0px !important;
  }

  .Home-Carousel-Title {
    background-color: #10508d;
    Color: White;
    padding: 10px !important;
  }

//HomePage WSBRAND style
  .Home-WSBRAND-Margin {
    height: 125px;
  }

  .Home-WSBRAND-textstyle {
    font-weight: bold;
  }

//HomePage Strength Style
  .Home-Strengths-Container-Margin {
    padding: 25px !important;
  }

  .Home-Strength-Card-Margin {
    padding: 20px 15px !important;
    margin: 0px 5px !important;
    min-width: 214px !important;
  }

  .Home-Strength-Card-Title-Margin {
    margin-bottom: 10px !important;
  }

//BrandPage BackGroundImage Style
  .bg-img-fluid {
    background-image: url("/img/backgrounds/banner.png");
    height: 300px !important;
  }

//BrandPage Brand style
  .Brands-Brands-Margin{
    min-height: 200px !important;
  }

//BrandPage Pattern style
  .Brands-Pattern-Tyre-IMG-Margin {
    width:100%; /*container-width*/
    height: 150px;
    overflow:hidden; /*hide bounds of image */
    margin:0;   /*reset margin of figure tag*/
  }

  .Brands-Pattern-Tyre-IMGA-Margin {
    display:block; /*remove inline-block spaces*/
    max-width: initial; /*make image streatch*/
    margin: -50% 0 -50% 50px;
    padding-top: 50%;
  }

  .Brands-Pattern-Battery-IMG-Margin {
    width:100%; /*container-width*/
    height: 350px;
    overflow:hidden; /*hide bounds of image */
    margin:0;   /*reset margin of figure tag*/
  }

  .Brands-Pattern-Battery-IMGA-Margin {
    width: 100% ;
    //display:block; /*remove inline-block spaces*/
    //max-width: initial; /*make image streatch*/
    //margin:-50% 0;
    //padding-top: 50%;
  }

    .retailer-card-size {
      width: 315px !important;
    }

    .retailer-detail-name {
      padding: 10px 10px !important;
    }

    .retailer-detail-address {
      padding: 10px 10px !important;
    }

    .arealink-padding {
      padding: 0 0 20px 0;
    }

// Mobile Meun Icon
.navbar-light .navbar-toggler {
  border-color: rgba(0, 0, 0, 0.1);
  right: 0px;
  position: absolute;
  margin: 20px;
  filter: brightness(100);
}

.cardSize {
  width: 398px !important;
}

.mnp {
  margin: 0 !important;
  padding: 0 !important;
}

.news-title {
  font-weight: bold;
  color: #1f3c7d;
}

.news-text {
  font-weight: 700;
}

.jumbotron {
   margin-bottom: 0 !important;
}

.news {
  padding: 0 !important;
  margin-top: 30px !important;
}

.newscard {
  padding: 5px !important;

}

.newsimg {
  padding: 10px !important;
}

.newstext {
  padding: 0px 10px !important;
  top: 405px;
  position: absolute;
}

.newstitle {
  border-left: 4px #f9a825 solid !important;
  padding-left: 2% !important;
  color: #1f3c7d;
  font-weight: bolder;

}

.newscard-detail {
  border-top: 4px #000 solid  !important;
  height: 525px;
}


.newstitle-interview {
  background-image:-webkit-linear-gradient(
                  to right,
                  rgba(0,0,0,0) 0%,
                  rgba(0,0,0,0.4) 5%,
                  rgba(0,0,0,0.6) 10%,
                  rgba(0,0,0,0.6) 90%,
                  rgba(0,0,0,0.4) 95%,
                  rgba(0,0,0,0) 100%);
  background-image: radial-gradient(circle, rgba(0, 0, 0, 0.6) 64%, rgba(0, 0, 0, 0.6) 70%, rgba(0, 0, 0, 0.4) 80%, rgba(0, 0, 0, 0) 95%);
  text-align: center;
  position: absolute;
  width: 100%;
}

.Gift {
  bottom: 0px;
  position: absolute;
  left: 5px;
}

.WorryFree {
  top: 30px;
  position: absolute;
  left: 0px;
}

.card-title {
  font-weight: 800;
  color: #1f3c7d;
}

.pattern-description{
  padding: 10px 0;
}

.subtitle {
  font-size: 20px !important;
  font-weight: bold !important;
  padding-bottom: 10px !important;
}

.subtext {
  font-size: 16px;
}

.promotion-svg {
  background-color: #eceeef;
}

.svg-style {
  height: 40px;
  width: inherit;
}

.svg-text {
  position: absolute;
  top: 0px;
  color: white;
  padding-left: 20px;
  font-size: 28px;
}